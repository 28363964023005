<template>
  <div
    style="background: #f8f8f8; min-height: 100vh; padding-top: 1px"
    ref="examBox"
  >
  <Header/>
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="$router.go(-1)">练习与考试</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">{{ pageTitle }}试卷</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="exam-box">
      <ul class="exam-paper">
        <li
          v-for="(topic, index) in examTopic"
          :key="topic.id"
          :ref="`item${index}`"
          :data-no="index"
        >
          <!-- 单选 -->
          <p v-if="topic.topic_type === 1">
            <span class="title"
              >{{ index + 1 }}、<b>(单选题)</b>
              <i> {{ topic.question }}</i>
            </span>
            <label
              @click.prevent="getLitemRef(topic.id, key, index)"
              v-for="(value, key, topic_index) in JSON.parse(topic.answer)"
              :key="topic_index"
            >
              <input type="radio" name="choice" hidden />
              <span
                class="option-item"
                :class="{ optionactive: activeId[topic.id] == key }"
                >{{ key }}</span
              >
              <span class="option-content">{{ value }}</span>
            </label>
          </p>
          <!-- 多选 -->
          <p v-if="topic.topic_type === 2">
            <span class="title"
              >{{ index + 1 }}、<b>(多选题)</b>
              <i> {{ topic.question }}</i>
            </span>
            <label
              v-for="(value, key, topic_index) in JSON.parse(topic.answer)"
              :key="topic_index"
            >
              <input
                type="checkbox"
                name="chkbox"
                hidden
                :value="key"
                @click="chkClick($event, topic.id, key, index)"
              />
              <span
                class="option-item"
                :class="{
                  chkactive:
                    activeChk[topic.id] &&
                    activeChk[topic.id].indexOf(key) != -1,
                }"
                >{{ key }}</span
              >
              <span class="option-content">{{ value }}</span>
            </label>
          </p>
          <!-- 判断 -->
          <p v-if="topic.topic_type === 3">
            <span class="title"
              >{{ index + 1 }}、<b>(判断题)</b>
              <i> {{ topic.question }}</i>
            </span>
            <label
              v-for="(value, key, topic_index) in JSON.parse(topic.answer)"
              :key="topic_index"
            >
              <input
                type="radio"
                name="judge"
                hidden
                @click="judegClick(topic.id, value, index)"
                :value="value"
              />
              <span
                class="option-content-judge"
                :class="{ judgeactive: activeId[topic.id] == value }"
                >{{ value }}</span
              >
            </label>
          </p>
        </li>
      </ul>
      <ExamCard
        @toQuestion="toQuestion"
        @handPaper="handPaper"
        :userAnswer="userAnswer"
        :timer="timer"
      />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import ExamCard from "../components/ExamCard.vue";
export default {
  data() {
    return {
      h: 0,
      pageTitle: "考试试卷",
      examInfo: {},
      examTopic: [],
      answerId: [],
      activeId: {}, //单选题选中样式
      activeChk: {}, //多选样式
      mckdArr: [],
      userAnswer: [], //用户答案
      timer: "",
      uPaperId: "",
      oId: "",
      certInfo: {},
    };
  },

  components: { Header, ExamCard },

  computed: {},
  mounted() {
    if (this.$route.query.examid) {
      this.getPage();
    } else {
      this.getPractice();
    }
  },
  methods: {
    // 单选
    getLitemRef(qid, key, i) {
      this.activeId[qid] = key;
      this.userAnswer.forEach((ele) => {
        if (ele.question_id == qid) {
          ele.user_answer = key;
        }
      });
      this.$forceUpdate();
      this.$refs[`item${i + 1}`][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // 多选题添加到数组中
    chkClick(e, qid, key, i) {
      if (this.answerId.indexOf(qid) == -1) {
        this.answerId.push(qid);
      }
      if (e.target.checked) {
        if (qid == this.oId || this.oId == "") {
          this.mckdArr.push(key);
        } else {
          this.mckdArr = [];
          this.mckdArr.push(key);
        }
      } else {
        if (this.oId == qid || this.oId == "") {
          this.mckdArr.forEach((ele, ind) => {
            if (ele == key) {
              this.mckdArr.splice(ind, 1);
            }
          });
        }
      }
      let arrString = this.mckdArr.join(",");
      this.userAnswer.forEach((ele) => {
        if (ele.question_id == qid) {
          ele.user_answer = arrString;
        }
      });
      if (!this.activeChk[qid]) {
        this.activeChk[qid] = [];
      }
      for (let val in this.activeChk) {
        if (val == qid && this.activeChk[val].indexOf(key) == -1) {
          this.activeChk[val].push(key);
        } else if (val == qid && this.activeChk[val].indexOf(key) != -1) {
          this.activeChk[val].splice(this.activeChk[val].indexOf(key), 1);
        }
      }
      // this.$refs[`item${i + 1}`][0].scrollIntoView({
      //   behavior: "smooth",
      //   block: "start",
      // });

      console.log(this.userAnswer);
      this.oId = qid;
      this.$forceUpdate();
    },
    // 判断题加入数组
    judegClick(qid, val, i) {
      this.activeId[qid] = val;
      console.log(this.activeId);
      if (this.answerId.indexOf(qid) == -1) {
        this.answerId.push(qid);
      }
      this.userAnswer.forEach((ele) => {
        if (ele.question_id == qid) {
          ele.user_answer = val;
        }
      });
      this.$forceUpdate();
      if (i !== this.userAnswer.length - 1) {
        this.$refs[`item${i + 1}`][0].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },

    toQuestion(i) {
      this.$refs[`item${i}`][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    getPage() {
      let fd = new FormData();
      this.pageTitle = this.$route.query.examtitle;

      fd.append("course_id", this.$route.query.examid);
      this.axios.post("/exam/afterclass_exam", fd).then((res) => {
        this.examInfo = res.data.data;
        this.examTopic = res.data.question_list;
        this.uPaperId = res.data.u_paper_id.id;
        this.minReturn(this.examInfo.analog_answer_time);
        this.examTopic.forEach((ele) => {
          let obj = {};
          this.answerId.push(ele.id);
          obj["question_id"] = ele.id;
          obj["user_answer"] = "";
          obj["topic_type"] = ele.topic_type;
          this.userAnswer.push(obj);
        });
      });
    },
    getPractice() {
      const { vid, pt } = this.$route.query;
      this.pageTitle = pt;
      let fd = new FormData();
      fd.append("video_id", vid);
      this.axios.post("/exam/practice_exam", fd).then((res) => {
        console.log(res);
        this.examTopic = res.data;
        this.examTopic.forEach((ele) => {
          let obj = {};
          this.answerId.push(ele.id);
          obj["question_id"] = ele.id;
          obj["user_answer"] = "";
          obj["topic_type"] = ele.topic_type;
          this.userAnswer.push(obj);
        });
      });
    },
    // 倒计时
    formatSeconds(time) {
      let result = parseInt(time);
      let h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);

      let res = "";
      if (h !== "00") res += `${h} : `;
      if (m !== "00") res += `${m} : `;
      res += `${s}`;
      return res;
    },
    minReturn(second) {
      let timer = setInterval(() => {
        second--;
        this.timer = this.formatSeconds(second);
        console.log(second);
        if (second <= 0) {
          this.$message.success("考试结束,自动交卷");
          clearInterval(timer);
          this.handPaper();
        }
      }, 1000);
    },
    // 考试用时
    durationTime(time) {
      let len = time.split(":");
      if (len.length == 3) {
        let hour = time.split(":")[0];
        let min = time.split(":")[1];
        let sec = time.split(":")[2];
        return Number(hour * 3600) + Number(min * 60) + Number(sec);
      }
      if (len.length == 2) {
        let min = time.split(":")[0];
        let sec = time.split(":")[1];
        return Number(min * 60) + Number(sec);
      }
      if (len.length == 1) {
        let sec = time.split(":")[0];
        return Number(sec);
      }
    },
    // 交卷
    handPaper() {
      if (this.$route.query.examid) {
        let overTime = this.durationTime(this.timer);
        let duraTime = Number(this.examInfo.analog_answer_time) - overTime;
        let fd = new FormData();
        fd.append("paper_id", this.examInfo.id);
        fd.append("u_paper_id", this.uPaperId);
        fd.append("question_id", this.answerId);
        fd.append("user_answer", JSON.stringify(this.userAnswer));
        fd.append("answer_time", duraTime);
        this.axios.post("/exam/afterclass_submit", fd).then((res) => {
          if (res.data.code == 1) {
            this.$message.error("考试不及格");
            setTimeout(() => {
              this.$router.replace(
                `/results?pid=${this.uPaperId}&etitle=${this.pageTitle}`
              );
            }, 800);
          } else {
            this.$message.success("考试及格");
            setTimeout(() => {
              this.$router.replace(
                `/results?pid=${this.uPaperId}&etitle=${this.pageTitle}`
              );
            }, 800);
          }
        });
      } else {
        let fd = new FormData();
        fd.append("question_id", this.answerId);
        fd.append("user_answer", JSON.stringify(this.userAnswer));
        this.axios.post("/exam/practice_analysis", fd).then((res) => {
          sessionStorage.setItem("panswer", JSON.stringify(res.data));
          this.$router.replace(`/results?etitle=${this.pageTitle}`);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.exam-box {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .exam-paper {
    width: 893px;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    margin-bottom: 55px;
    P {
      margin: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    li {
      list-style-type: none;
      width: 100%;
      padding: 30px 20px;
      box-sizing: border-box;
      border-bottom: 1px dashed #d1cfcf;
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      &:last-child {
        border: 0;
      }
      .title {
        b {
          font-weight: normal;
          color: #fa711b;
          margin: 0 5px;
        }
        i {
          font-style: normal;
        }
      }
      label {
        cursor: pointer;
        margin-top: 20px;
        margin-left: 33px;
        flex-shrink: 0;

        .option-item {
          width: 23px;
          height: 23px;
          line-height: 23px;
          display: inline-block;
          color: #fb772b;
          border-radius: 50%;
          border: 1px solid #fb772b;
          text-align: center;
          margin-right: 15px;
        }
        .option-content-judge {
          position: relative;
          margin-left: 38px;
          &::before {
            content: "";
            width: 12px;
            height: 12px;
            display: inline-block;
            border: 1px solid #fb772b;
            position: absolute;
            top: 50%;
            left: -35px;
            transform: translateY(-50%);
          }
        }
        // input:checked + span.option-content-judge::before {
        //   background-color: #fb772b;
        // }
        .optionactive {
          color: #fff;
          background-color: #fb772b;
        }
        .chkactive {
          color: #fff;
          background-color: #fb772b;
          border: 1px solid #fb772b;
        }
        .judgeactive {
          &::before {
            content: "";
            width: 12px;
            height: 12px;
            display: inline-block;
            border: 1px solid #fb772b;
            background-color: #fb772b;
            position: absolute;
            top: 50%;
            left: -35px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
</style>
